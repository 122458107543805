<template>
  <div class="home">
     <div class="banner glo-relative">
        <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
          <van-swipe-item v-for="(item,index) in swipeImg" :key="index">
            <img :src="item" />
          </van-swipe-item>
        </van-swipe>
        <div class="mark-text">
          <div>
            <i :class="`qi-${weather.icon} img-weather`"></i>
            {{weather.text || '多云'}} 温度 {{ weather.temp || 0 }}℃
          </div>
          <div>湿度 {{ weather.humidity || 0 }}%</div>
          <div>风速 {{ weather.windSpeed || 0 }}km/h {{weather.windDir}}</div>
          <div>降水量 {{ weather.precip || 0 }}mm</div>
          <div>N {{lon || '0.00000'}}，E {{lat || '0.00000'}}</div>
          <div v-if="location">{{location}}</div>
        </div>
    </div>
    <van-divider
      :style="{
        color: '#2cb5e8',
        borderColor: '#2cb5e8',
        padding: '0 16px',
        margin: '0 0 12px 0',
      }"
    >
      {{currentScene === 'level' ? '水位模式' : currentScene === 'water' ? '积水模式' : '测距模式'}}
    </van-divider>
    <div class="monitor-bridge glo-relative">
      <van-loading color="#0094ff" style="line-height: 47.5vh" v-if="loading" />
      <div v-else >
        <img class="wlmsImg" :src="wlmsImg" />
        <canvas id="flowingWater"></canvas>
        <div class="setting-param" v-if="authTags.includes('wlms:param:config')">
          <van-icon name="setting-o" class="icon"  @click="goParam"/>
        </div>
        <div v-if="currentScene === 'level'" class="level-tag" >
          <div class="level-position-0">
            <div class="flex-inline" v-if="absoluteLevel">
              <div class="text">绝对水位</div>
              <div class="num">{{absoluteLevel}}<span>m</span></div>
            </div>
            <div class="flex-inline" v-if="waterLevel">
              <div class="text">相对水位</div>
              <div class="num">{{waterLevel}}<span>m</span></div>
            </div>
          </div>
          <div class="level-position-1">
            <div class="flex-inline">
              <div class="text">水深</div>
              <div class="num">{{waterDeep}}<span>m</span></div>
            </div>
          </div>
          <div class="level-position-2">
            <div class="flex-column">
              <div class="num">{{curDist}}<span>m</span></div>
              <div class="text">水面距离</div>
            </div>
          </div>
          <div class="level-position-3">
            <div class="flex-column">
              <div class="text">警戒水位</div>
              <div class="num">{{alarmLine}}<span>m</span></div>
            </div>
          </div>
        </div>
        <div v-else-if="currentScene === 'water'" class="water-tag">
          <!-- 井 -->
          <div v-if="subSceneType === 'well'">
            <div class="water-pos welldeep-fixed" v-if="waterDeep !== null">
              <div class="flex-column">
                <div class="num">{{waterDeep}}<span>cm</span></div>
                <div class="text">积水深度</div>
              </div>
            </div>
            <div class="water-pos welldict-fixed">
              <div class="flex-column">
                <div class="num">{{curDist}}<span>m</span></div>
                <div class="text">水面距离</div>
              </div>
            </div>
            <!-- <div :class="{
              'water-pos':true,
              'welldeep-0':rate>0 && rate<=10,
              'welldeep-1':rate>10 && rate<=40,
              'welldeep-2':rate>40 && rate<=60,
              'welldeep-3':rate>60 && rate<=90,
              'welldeep-4':rate>90,
            }" v-if="waterDeep !== null">
              <div class="flex-column">
                <div class="num">{{waterDeep}}<span>cm</span></div>
                <div class="text">积水深度</div>
              </div>
            </div>
            <div :class="{
              'water-pos':true,
              'welldict-0':rate>0 && rate<=10,
              'welldict-1':rate>10 && rate<=40,
              'welldict-2':rate>40 && rate<=60,
              'welldict-3':rate>60 && rate<=90,
              'welldict-4':rate>90,
            }">
              <div class="flex-column">
                <div class="num">{{curDist}}<span>m</span></div>
                <div class="text">水面距离</div>
              </div>
            </div> -->
          </div>
          <!-- 默认 -->
          <div v-else>
            <div class="water-position-0" v-if="waterDeep !== null">
              <div class="flex-column">
                <div class="num">{{waterDeep}}<span>cm</span></div>
                <div class="text">积水深度</div>
              </div>
            </div>
            <div class="water-position-1">
              <div class="flex-column">
                <div class="num">{{curDist}}<span>m</span></div>
                <div class="text">水面距离</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="currentScene === 'range'" class="range-tag">
          <div class="range-position-0">
            <div class="flex-column">
              <div class="num">{{curDist}}<span>m</span></div>
              <div class="text">测量距离</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hint-time">更新时间：{{lastUpdateTime || '0000-00-00 00:00:00'}}</div>
    <!-- <div class="handle-btn" @click="goParam">
      参数设置
    </div> -->
    <van-divider
      :style="{
        color: '#2cb5e8',
        borderColor: '#2cb5e8',
        padding: '0 16px',
        margin: '12px 0',
      }"
    >
      测量水位变化曲线
    </van-divider>
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item title="自定义筛选时间" class="filter" name="1">
        <van-cell
          title="请选择开始时间"
          :value="filter.startVal.toString()"
          is-link
          @click="filter.startShow = true"
        />
        <van-cell
          title="请选择结束时间"
          :value="filter.endVal"
          is-link
          @click="filter.endShow = true"
        />
        <van-row class="text-center">
          <van-col span="12"
            ><van-button type="primary" size="small" round @click="resetData"
              >重置</van-button
            ></van-col
          >
          <van-col span="12">
            <van-button type="primary" size="small" round @click="filterData"
              >筛选</van-button
            ></van-col
          >
        </van-row>
      </van-collapse-item>
    </van-collapse>
    <div class="nav-char-custom">
      <div v-for="(item,index) in navlist" :key="index" class="wid_3">
          <div :class="{'block': true,'active': active == index ? true : false}" @click="selectTime(index)"><i :class="item.className"></i>{{ item.title }}</div>
      </div>
    </div>
    <div>
      <div v-if="chartLoading">
        <van-loading type="spinner" vertical color="#0094ff" >加载中</van-loading>
      </div>
      <div v-else>
        <div><canvas id="chartWater"></canvas></div>
      </div>
    </div>
    <!-- 筛选时间段 -->
    <van-popup v-model="filter.startShow" round position="bottom">
      <van-datetime-picker
        v-model="filter.start"
        type="datetime"
        title="选择开始时间"
        :filter="filterOption"
        @confirm="confromStartTime"
        @cancel="filter.startShow = false"
      />
    </van-popup>
    <van-popup v-model="filter.endShow" round position="bottom">
      <van-datetime-picker
        v-model="filter.end"
        type="datetime"
        title="选择结束时间"
        :filter="filterOption"
        @confirm="confromEndTime"
        @cancel="filter.endShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
const F2 = require("@antv/f2");
import moment from "moment";
export default {
  name: "Home",
  data() {
    return {
      swipeImg: [],
      magbotVal: "",
      weather: {
        icon: "104",
        updateTime: "2020-01-01T00:00+00:00",
      },
      chartData:[],
      currentScene:null,
      devCode:null,
      wlmsImg:null,
      waterLevel:0,
      absoluteLevel:0,
      waterDeep:0,
      curDist:0,
      location:'',
      lon:'',
      lat:'',
      lastUpdateTime:'',
      alarmLine:0,
      loading:true,
      chartLoading:true,
      active:0,
      searchType:'day',
      navlist:[
        {className:'fa fa-line-chart',title:'日视图'},
        {className:'fa fa-line-chart',title:'周视图'},
        {className:'fa fa-line-chart',title:'月视图'}
      ],
      chart0:null,
      activeName: "",
      filter: {
        start: new Date(),
        end: new Date(),
        startVal: "",
        endVal: "",
        startShow: false,
        endShow: false,
      },
      subSceneType:null,
      projectCode:null,
      authTags:[],
      rate:0
    }
  },
  methods: {
    // 时间选项过滤
    filterOption(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 10 === 0);
      }
      return options;
    },
    // 确认开始时间
    confromStartTime(value) {
      this.filter.startVal = moment(value).format("yyyy-MM-DD HH:mm:ss");
      this.filter.startShow = false;
    },
    // 确认结束时间
    confromEndTime(value) {
      this.filter.endVal = moment(value).format("yyyy-MM-DD HH:mm:ss");
      this.filter.endShow = false;
    },
    // 重置
    resetData() {
      this.filter = {
        start: new Date(),
        end: new Date(),
        startVal: "",
        endVal: "",
        startShow: false,
        endShow: false,
      };
    },
    // 筛选
    filterData() {
      this.chartLoading = true;
      this.active = null;
      !this.filter.startVal && !this.filter.endVal ? this.searchType = 'day' : void 0; // 当未填写自定义时间时 重置为day
      this.getChartData();
    },
    // 点击切换绘制
    selectTime(index){
      this.chartLoading = true;
      this.resetData(); // 重置
      this.chart0.clear(); // 清除
      this.active = index;
      if(this.active == 0){
        this.searchType = 'day';
      }else if(this.active == 1){
        this.searchType = 'week';
      }else if(this.active == 2){
        this.searchType = 'month';
      }
      this.getChartData();
    },
    getChartData(){
      return new Promise(resolve => {
        this.$api.WLMS.wlmsChartsData({
          projectCode:this.projectCode,
          devCode:this.devCode,
          beginTime:this.filter.startVal || void 0,
          endTime:this.filter.endVal || void 0,
          searchType:this.filter.startVal || this.filter.endVal ? void 0 : this.searchType
        }).then( d => {
          this.currentScene = d[0].sceneType;
          let arrWater = [];
          for(let i=0;i<d.length;i++){
              d[i].time.forEach((e,index)=>{
                  if(d[i].level.length > 0){
                    arrWater.push({
                      date:e.substring(0,e.lastIndexOf(':')),
                      value:parseFloat(d[i].level[index]),
                      name:'相对水位'
                    });
                  }if(d[i].absLevel.length > 0){
                    arrWater.push({
                      date:e.substring(0,e.lastIndexOf(':')),
                      value:parseFloat(d[i].absLevel[index]),
                      name:'绝对水位'
                    });
                  }
                  if(d[i].deep.length > 0){
                    arrWater.push({
                      date:e.substring(0,e.lastIndexOf(':')),
                      value:parseFloat(d[i].deep[index]),
                      name:this.currentScene === 'range' ? '距离' :'水深'
                    });
                  }
              })
          }
          this.chartDataWater = arrWater;
          this.chartLoading = false;
          this.chartShow();
          resolve();
        })
      })
    },
    // 绘制图表
    chartShow() {
      const allVal = this.chartDataWater.map( v => v.value);
      const maxVal = Math.max(...allVal);
      this.$nextTick(() => {
        // 水位变化
        this.chart0 = new F2.Chart({
          id: "chartWater",
          pixelRatio: window.devicePixelRatio,
        });
        this.chart0.source(this.chartDataWater);
        this.chart0.scale("date", {
          type: "timeCat",
          mask: "MM-DD HH:mm",
          tickCount: 4,
          range: [0, 1]
        });

        const otherOpt = {
          tickCount: 6,
          min:0,
        }
        let waterOpt;
        if(maxVal >0 && maxVal <= 5){
          waterOpt = {
            min:0,
            max:5,
            tickInterval:1  // 数值范围10
          }
        }else if(maxVal > 6 && maxVal <= 50){
          waterOpt = {
            tickCount: 6,
            min:0,
            tickInterval:10  // 数值范围10
          }
        }else{
          waterOpt = {
            tickCount: 6,
            min:0,
          }
        }
        const optionValue = this.currentScene === 'water' ? waterOpt : otherOpt;

        this.chart0.scale("value", optionValue);
        this.chart0.axis("date", {
          label: function label(text, index, total) {
            const textCfg = {};
            if (index === 0) {
              textCfg.textAlign = "left";
            } else if (index === total - 1) {
              textCfg.textAlign = "right";
            }
            return textCfg;
          },
        });
        this.chart0.tooltip({
          custom: true, // 自定义 tooltip 内容框
          showXTip: true, // 是否展示 X 轴的辅助信息
          xTip: (obj)=>{
            return obj
          },
          onChange: (obj) => {
            const legend = this.chart0.get('legendController').legends.top[0];
            const tooltipItems = obj.items;
            const legendItems = legend.items;
            const map = {};
            legendItems.forEach((item)=>{
              map[item.name] = item;
            });
            tooltipItems.forEach((item)=> {
              const name = item.name;
              const value = item.value;
              if (map[name]) {
                this.currentScene === 'water' ? map[name].value = value + 'cm' :map[name].value = value + 'm';
              }
            });
            legend.setItems(Object.values(map));
          },
          onHide:() => {
            const legend = this.chart0.get('legendController').legends.top[0];
            legend.setItems(this.chart0.getLegendItems().country);
          }
        });
        this.chart0
          .line({
            connectNulls: true, // 将空数据连接
          })
          .shape("smooth")
          .position("date*value").color('name')
        this.chart0.render();
      });
    },
    // 获取天气预报信息
    getWeather() {
      this.$api.WLMS.weatherInfo({
        projectCode:this.projectCode,
        devCode:this.devCode
      }).then((d) => {
        if (!d || d.code == 400) return;
        this.weather = { ...d.now };
        this.weather.updateTime = d.updateTime;
      });
    },
    getWaterVal(){
      return new Promise( resolve => {
        this.$api.WLMS.deviceMonitorInfo({
            projectCode:this.projectCode,
            devCode:this.devCode
        }).then( d => {
          this.subSceneType = d[0].subSceneType;
          // 轮播图
          d[0].picUrl 
          ? this.swipeImg = d[0].picUrl.split(';') 
          : this.swipeImg = [
            require("@/assets/wlms/img/apply/img-0.jpg"),
            require("@/assets/wlms/img/apply/img-1.jpg"),
            require("@/assets/wlms/img/apply/img-2.jpg"),
            require("@/assets/wlms/img/apply/img-3.jpg")
          ];
          // 基础信息
          this.lon = d[0].lon;
          this.lat =d[0].lat;
          this.location = d[0].location;
          this.lastUpdateTime = d[0].lastUpdateTime;
          this.currentScene = d[0].sceneType;
          if(!d[0].sceneType){
            this.$toast('未设置场景值，请先设置场景值');
            setTimeout(() => {
              this.goParam();
            },800)
            return false;
          }
          // 测量信息
          this.waterLevel = d[0].wlmsStatus ? d[0].wlmsStatus.waterLevel : 0;
          this.absoluteLevel = d[0].wlmsStatus ? d[0].wlmsStatus.absoluteLevel :0;
          this.waterDeep = d[0].wlmsStatus ? d[0].wlmsStatus.waterDeep : 0;
          this.curDist = d[0].wlmsStatus ? d[0].wlmsStatus.curDist : 0;
          resolve(d[0]);
        })
      })
    },
    async getConfigInfo(){
      const item = await this.getWaterVal();
      console.log('数据：',item);
      if(item.sceneType === 'level'){
        this.$api.WLMS.echoConfig({
          devCode:this.devCode,
          sceneType:item.sceneType
        }).then( d => {
          this.alarmLine = d.alarmLine/100 || 0; // cm换算m
        })
      }
      this.wlmsImg = await this.getNewImg(item.sceneType,item);
      this.loading = false;
      setTimeout(()=>{
        this.getFlowingWater(item);
      },500)
      this.getChartData();
    },
    // 封装状态判断展示图片
    getNewImg(scene,item){
      let img = null;
      let rate = 0;
      if(scene === 'level'){
        img = require('@/assets/wlms/img/scene-level.png');        
      }else if(scene === 'water'){
        // 积水深度
        rate = Math.round( ((item.wlmsStatus.waterDeep/100) / (item.wlmsStatus.waterDeep/100 + item.wlmsStatus.curDist)) * 100);
        console.log(`积水当前比例：${rate}%`);
        if(item.subSceneType === 'well'){
          img = require('@/assets/wlms/img/scene-water/well-bg.png');
          // 分阶段展示水位变化量
          // if(rate>0 && rate<=10){
          //   img = require('@/assets/wlms/img/scene-water/well0.png');
          // }else if(rate>10 && rate<=40){
          //   img = require('@/assets/wlms/img/scene-water/well1.png');
          // }else if(rate>40 && rate<=60){
          //   img = require('@/assets/wlms/img/scene-water/well2.png');
          // }else if(rate>60 && rate<=90){
          //   img = require('@/assets/wlms/img/scene-water/well3.png');
          // }else if(rate>90){
          //   img = require('@/assets/wlms/img/scene-water/well4.png');
          // }
        }else{
          img = require('@/assets/wlms/img/scene-water/tunnel0.png');
        }
      }else if(scene === 'range'){
        img = require('@/assets/wlms/img/scene-range.png')
      }

      this.rate = rate || 0;
      return img
    },
    getFlowingWater(item){
      if(item.sceneType === 'water' && item.subSceneType === 'well'){
        const reference = document.getElementsByClassName('wlmsImg')[0];
        const canvas = document.getElementById('flowingWater');
        canvas.setAttribute('width',reference.offsetWidth);
        canvas.setAttribute('height',reference.offsetHeight);

        const waterHeight = canvas.offsetHeight * (this.rate/100);
        const bgHeight = canvas.offsetHeight - waterHeight;
        //创建画布
        const app = canvas.getContext('2d');
        //设置填充颜色
        app.fillStyle = '#505153';
        //绘制图形
        app.fillRect(0, 10, canvas.offsetWidth, bgHeight);
        app.fillStyle = '#82D6EA';
        //绘制图形
        app.fillRect(0, bgHeight, canvas.offsetWidth, waterHeight);
      }
    },
    // 跳转参数页
    goParam(){
      this.$router.push({path:'/wlms/setting',query:{devCode:this.devCode}})
    }
  },
  mounted() {
    this.devCode = this.$route.query.devCode;
    this.projectCode = window.sessionStorage.getItem('projectCode');
    this.authTags = window.localStorage.getItem('authTags');
    this.getWeather();
    this.getConfigInfo();
  }
};
</script>

<style lang="scss" scoped>
.chart-content {
  // padding-top: 8%;
  .time {
    font-size: 2.5rem;
    color: rgb(92, 75, 66);
    margin-bottom: 4%;
    font-weight: bold;
  }
  .tag {
    color: #a5a5a5;
    letter-spacing: 2px;
    font-size: 0.85rem;
  }
}
.flex {
  display: flex;
  justify-content: left;
  align-self: center;
  padding-left: 25%;
}
.weather {
  padding: 4% 4% 2% 4%;
  .content {
    padding: 2.5%;
    background-color: #78baf3;
    // background-image: linear-gradient(141deg,#fbfdfc 0%,#2cb5e8 51%,#58bde2 75%);
    background-image: linear-gradient(
      141deg,
      #fbfdfc 0%,
      #51c4ef 51%,
      #5bd4cf 75%
    );
    border-radius: 15px;
    box-shadow: 3px 1px 5px 0px #b1b1b1;
    .weather-icon {
      width: 50px;
      height: 50px;
    }
    .weather-text {
      font-size: 0.9rem;
      line-height: 2;
      white-space: nowrap;
      .tep {
        font-size: 1.6rem;
        font-weight: bold;
      }
    }
    .iconfont {
      font-size: 1.5rem;
      color: #f1f1f1;
      line-height: 1;
    }
    .text {
      font-size: 0.85rem;
      color: #f1f1f1;
    }
    .update-time {
      font-size: 0.5rem;
      color: #5888ab;
      text-align: right;
      padding-top: 4%;
    }
  }
}
// 桥梁结构动画
.monitor-bridge {
  margin: 0 4%;
  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
  .setting-param{
    position: absolute;
    top:2%;
    right: 5%;
    background-color: #15bfc7;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    .icon{
      font-size: 1.5rem;
      color: #fff;
      &:active{
        opacity: 0.7;
      }
    }
  }
  .level-tag{
    .level-position-0{
      position:absolute;
      left: 62%;
      bottom: 20%;
    }
    .level-position-1{
      position: absolute;
      right: 8%;
      bottom: 5%;
    }
    .level-position-2{
      position: absolute;
      top: 40%;
      right: 24%;
    }
    .level-position-3{
      position: absolute;
      bottom: 19%;
      left: 33%;
      .num{
        color:#e6832e;
        font-size:.85rem;
      }
    }
  }
  .water-tag{
    .water-position-0{
      position:absolute;
      left: 52%;
      bottom: 23%;
    }
    .water-position-1{
      position:absolute;
      left: 55%;
      top: 40%;
    }
    .water-pos{
      position:absolute;
      .text{color:#e3e3e3}
    }
    .welldeep-fixed{
      right: 10%;
      bottom: 25%;
    }
    // .welldeep-0{
    //   right: 13%;
    //   bottom: 4%;
    // }
    // .welldeep-1{
    //   right: 12%;
    //   bottom: 20%;
    // }
    // .welldeep-2{
    //   right: 12%;
    //   top: 40%;
    // }
    // .welldeep-3{
    //   right: 12%;
    //   top: 22%;
    // }
    // .welldeep-4{
    //   right: 13.6%;
    //   top: 6%;
    // }
    .welldict-fixed{
      left: 33%;
      top: 18%;
    }
    // .welldict-0{
    //   left: 34%;
    //   top: 40%;
    // }
    // .welldict-1{
    //   left: 34%;
    //   top: 40%;
    // }
    // .welldict-2{
    //   left: 34%;
    //   top: 34%;
    // }
    // .welldict-3{
    //   left: 34%;
    //   top: 18%;
    // }
    // .welldict-4{
    //   top: 6%;
    //   left: 15%;
    // }
  }
  .range-tag{
    .range-position-0{
      position:absolute;
      left: 60%;
      top: 40%;
    }
  }
  .flex-inline{
    display: flex;
    align-items: center;
  }
  .text{
    color:#636363;
    font-size: .75rem;
    padding-right: 4px;
  }
  .num{
    color: #15bfc7;
    font-size: 1.2rem;
    font-weight: bolder;
    span{
      font-size: .75rem;
    }
  }
}
.banner {
  padding: 0 10px;
  img {
    width: 100%;
    height: 16rem;
    border-radius: 10px;
  }
}
.mark-text{
  position: absolute;
  top: 2%;
  left: 5%;
  text-align: left;
  color: #fff;
  font-size: .8rem;
  padding: 10px;
  border-radius: 10px;
  background-color: #79757569;
  div{
    margin-bottom: 2px;
  }
  .img-weather{
    font-size: 1.2rem;
  }
}
.hint-time{
  font-size: .7rem;
  text-align: right;
  padding-right: 20px;
  margin-bottom: 10px;
}
.handle-btn{
  background: #15bfc7;
  margin: 12px;
  border-radius: 10px;
  line-height: 2.5;
  font-size: .95rem;
  color: #fff;
  &:active{
    opacity: 0.7;
  }
}
.nav-char-custom{
  padding: 4%;
  display: flex;
  justify-content: space-around;
  .fa{padding-right: 4px;}
  .wid_3{width: 30%;}
  .block{
    line-height: 2;
    padding: 2% 4%;
    border-radius: 10px;
    color: #585858;
    box-shadow: 1px 1px 5px #dadada;
    font-weight: bold;
    margin-right: 2%;
    text-align: center;
    font-size: 0.9rem;
  }
  .active{
    background:linear-gradient(#99d2e6 0%, #2fd4bd 85%);
    color: whitesmoke;
  }
}
#chartWater{
  width: 100%;
  height: 15rem;
}
.filter{
  text-align: left;
  .text-center {
    text-align: center;
    button {
      padding: 0 20px;
      margin-top: 5%;
    }
  }
}
#flowingWater{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  box-sizing: border-box;
}
</style>